@import "animations";

$breakpoint-mobile: 800px;
$breakpoint-smallmobile: 350px;

#App {
    margin-top: 90px; //offset the rest of the page for the navbar
}

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    background-color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    text-align: center;
    justify-content: center;
    margin: 0;
    font-size: 16px; // base font size
}

h1 {
    display: inline-block;
    font-size: 3rem;
    max-width: 750px;
    font-weight: bold;
}

h2 {
    font-size: 2rem;
}

p {
    margin: 1rem;
}

// used to jump to a position and offset the navbar
.anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
}

footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
    background-color: #c4c4c4;
    .cta {
        text-decoration: none;
        padding: 0.5rem 1rem;
        color: #ffffff;
        background-color: #1d1d1d;
        border-radius: 10px;
    }
    p {
        padding: 0 1rem;
        margin: 0;
    }
    ul {
        margin: 0;
        padding: 0 1rem;
        li {
            list-style: none;
        }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
        height: 110px;
        flex-direction: column;
    }
}

nav {
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    height: 90px;
    width: 100%;
    position: fixed;
    z-index: 600; /* navbar needs to be in front always */
    top: 0;
    left: 0;
    img {
        display: inline-block;
        height: 50px;
        padding: 0 1rem 0 26px;
    }
    ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
            margin: 0 10px;
        }
        a {
            transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            transition: .5s;
            text-decoration: none;
            color: #ffffff;
            padding: 1rem;
            border-radius: 8px;
            &:hover {
                background-color: #1d1d1d;
            }
        }
        .portal-link {
            color: #1d1d1d;
            background-color: #ffffff;
            cursor: pointer;
            &:hover {
                color: #ffffff;
                background-color: #1d1d1d ;
            }
        }
    }
    // drop down nav menu for mobile screens
    .dropdown {
        display: none;
        @media only screen and (max-width: $breakpoint-mobile) {
            position: relative;
            margin-right: 26px;
            display: inline-block;
            .dropbtn {
                background-color: #ffffff;
                color: #000000;
                padding: 15px 20px;
                border-radius: 10px;
                font-size: 1rem;
                border: none;
                cursor: pointer;
            }
            &:hover .dropbtn {
                background-color: #e76464;
                border-radius: 10px 10px 0 0;
            }
            &:hover .dropdown-content {
                display: block;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #e76464;
                width: 100%;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                text-align: center;
                a {
                    color: black;
                    padding: 15px 0;
                    text-decoration: none;
                    display: block;
                    &:nth-child(4) {
                        background-color: #fbe2e2;
                    }
                    &:nth-child(3) {
                        background-color: #f6c5c5;
                    }
                    &:nth-child(2) {
                        background-color: #f2a8a8;
                    }
                    &:nth-child(1) {
                        background-color: #ed8b8b;
                    }
                    &:hover {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }
    &.nav-transparent {
        background-color: transparent;
    }
    &.nav-colored {
        background-color: #000000;
    }
    @media only screen and (max-width: $breakpoint-mobile) {
        .desktop-nav-links {
            display: none;
        }
    }
}

//testing new logo changes
#animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 35vh;
    #logo {
        z-index: 500;
        display: block;
        // height: 140px;
        height: 50%;
        width: auto;
    }
    canvas {
        position: absolute;
        display: block;
        width: 100vw;
    }
    @media only screen and (max-width: $breakpoint-smallmobile) {
        height: 30vh;
    }
}

#landing {
    height: calc(100vh - 89px);
    #landing-content {
        height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
        color: #ffffff;
        * {
            // take all children to front
            z-index: 500;
        }
        .spacer {
            display: block;
            width: 100%;
            height: 40vh;
        }
        h1 {
            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 2.5rem;
                padding: 0;
            }
        }
        h2 {
            display: inline-block;
            font-size: 1.5rem;
            max-width: 500px;
            font-weight: 500;
            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 1.3rem;
                margin: 2rem 1rem 1rem 1rem;
                padding: 0 1rem;
            }
            @media only screen and (max-width: $breakpoint-smallmobile) {
                font-size: 1rem;
            }
        }
        .cta {
            color: initial;
            background-color: #ffffff;
            text-decoration: none;
            padding: 1rem 2rem;
            font-size: 1.5rem;
            border: none;
            border-radius: 20px;
            font-weight: bold;
            transition: background-color 0.5s ease;
            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 1rem;
            }
            @media only screen and (max-width: $breakpoint-smallmobile) {
                font-size: 1rem;
            }
        }
        .see-more {
            color: #ffffff;
            font-size: 4rem;
            @media only screen and (max-width: $breakpoint-mobile) {
                margin: 1rem 0;
            }
        }
    }
}

#services {
    background-color: #ffffff;
    .card-container {
        margin: 0 24px;
        padding: 0 24px 24px 24px; // spacing between cards
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
            padding: 0 0 1rem 0;
        }
    }
    .service-card {
        margin: 24px; // spacing between cards
        width: 270px;
        .service-icon svg {
            font-size: 100px;
        }
        p {
            margin: 0;
        }
        h3 {
            font-size: 2rem;
            font-weight: 500;
            margin: 1rem 0;
        }
        @media only screen and (max-width: $breakpoint-mobile) {
            margin: 1rem 0;
        }
    }
}

#about {
    background-color: #ffffff;
    color: #000000;
    .about-content {
        padding: 0 1rem 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .fb-page-container {
            h2 {
                margin: 1rem 0;
                @media only screen and (max-width: $breakpoint-mobile) {
                    margin: 2rem 0 1rem 0;
                }
            }
            margin: 1rem;
            width: 500px;
            @media only screen and (max-width: $breakpoint-mobile) {
                margin: 1rem;
                width: auto;
            }
        }
        p {
            font-size: 1.5rem;
            text-align: left;
            margin: 1rem;
            max-width: 720px;
            text-align: center;
            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 1.3rem;
            }
        }
        .video-container {
            @media only screen and (max-width: $breakpoint-mobile) {
                width: 100%;
                height: 40vh;
            }
            iframe {
                margin: 1rem;
                width: 700px;
                height: 394px;
                @media only screen and (max-width: $breakpoint-mobile) {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

#portal {
    color: #ffffff;
    margin: 90px 26px 2rem 26px;
    #welcome {
        text-align: left;
        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 1.2rem;
        }
    }
    #dropzone {
        padding: 1rem;
        border-radius: 6px;
        background-color: #1d1d1d;
    }
    #dropzone-border {
        border-style: dashed;
        border-radius: 6px;
        border-color: #ffffff;
        border-width: 2px;
        padding: 4rem 2rem;
    }
    #my-files {
        display: flex;
        flex-direction: column;
        #file-browser {
            height: 400px;
            text-align: left;
            .MuiDataGrid-window {
                overflow-x: hidden; // fix horizontal scrollbar issue
                @media only screen and (max-width: $breakpoint-mobile) {
                    overflow-x: visible;
                }
            }
            .download-button {
                height: 40px;
                width: 40px;
            }
            .no-files {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 6rem;
                margin: auto;
                padding: 1rem;
                p {
                    font-size: 1.5rem;
                }
            }
        }
        #file-header {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin: 3rem 0 1rem 0;
            h2 {
                margin: 0;
                @media only screen and (max-width: $breakpoint-mobile) {
                    margin-bottom: 1rem;
                }
            }
            .delete-button {
                height: 2rem;
            }
            @media only screen and (max-width: $breakpoint-mobile) {
                flex-direction: column;
            }
        }
        .loading-spinner {
            align-self: center;
            margin: 2rem auto;
        }
        .MuiDataGrid-columnSeparator:last-of-type {
            display: none;
        }
    }
}

#contact {
    background-color: #000000;
    color: #ffffff;
}

.contact-content {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1rem;
    button {
        text-transform: none;
    }
    .vertical-divider {
        background-color: #c4c4c4;
        align-self: stretch; // make height 100% of container
        width: 2px;
        margin: 8px 0px;
    }
    ol {
        width: 350px;
        margin: 0;
        display: flex;
        text-align: left;
        justify-content: left;
        list-style-type: none;
        flex-direction: column;
        padding: 8px 0;
        li {
            margin: 8px 0;
            padding: 8px 0;
            font-weight: 500;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            p {
                margin: 0;
            }
            .spacer {
                height: 8px;
            }
            .vertical-divider {
                margin: 0 7px 0 16px;
                width: 4px;
                background-color: #000000;
                @media only screen and (max-width: $breakpoint-mobile) {
                    margin: 0 7px 0 7px;
                }
            }
            @media only screen and (max-width: $breakpoint-mobile) {
                padding: 0.5rem 1rem;
            }
            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 1rem;
            }
        }
        @media only screen and (max-width: $breakpoint-mobile) {
            width: auto;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        width: 350px;
        padding: 8px 0;
        h2 {
            margin-top: 1rem;
        }
        .contact-input {
            margin: 8px 0;
        }
        button {
            border: none;
            font-size: 1.5rem;
            font-weight: bold;
            border-radius: 15px;
        }
        @media only screen and (max-width: $breakpoint-mobile) {
            width: 80%;
        }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
        flex-direction: column-reverse;
        align-items: center;
    }
}
#form-browser{
    .form-link{
        text-decoration: none;
        color: #ffffff;
        border-radius: 8px;
        background-color: #000000;
        transition: .5s;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            background-color: #1d1d1d;
        }
    }
}
.d2-form-modal {
    
    border-color: white;
    background-color: #000000;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    text-align: center;
    justify-content: center;
    margin: 0;
    font-size: 16px; // base font size
    
}