// for the star parallax effect
$star1: #fff8d6;
$star2: #c2deff;
$star3: #ffadad;
#star-container {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
    #stars {
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1369px 1230px $star1, 710px 932px $star1, 178px 463px $star1, 1223px 1797px $star1,
            1912px 1494px $star1, 1277px 35px $star1, 536px 1508px $star1, 422px 974px $star1, 275px 1496px $star1,
            552px 1867px $star1, 253px 1632px $star1, 193px 1060px $star1, 1622px 751px $star1, 1121px 1953px $star1,
            259px 1379px $star1, 787px 810px $star1, 1605px 1245px $star1, 380px 1683px $star1, 1101px 1269px $star1,
            97px 1561px $star1, 587px 1143px $star1, 845px 1746px $star1, 1831px 1467px $star1, 1503px 1395px $star1,
            1584px 404px $star1, 226px 1234px $star1, 287px 879px $star1, 1139px 346px $star1, 1277px 1941px $star1,
            483px 453px $star1, 607px 150px $star1, 1204px 1345px $star1, 1008px 590px $star1, 681px 334px $star1,
            1672px 745px $star1, 1596px 449px $star1, 30px 1121px $star1, 1px 1054px $star1, 1238px 820px $star1,
            428px 1699px $star1, 1685px 1307px $star1, 1292px 157px $star1, 1625px 821px $star1, 545px 1113px $star1,
            37px 677px $star1, 814px 259px $star1, 51px 1113px $star1, 853px 891px $star1, 545px 468px $star1,
            390px 411px $star1, 107px 1642px $star1, 650px 408px $star1, 638px 647px $star1, 1701px 732px $star1,
            270px 1806px $star1, 27px 1913px $star1, 1594px 907px $star1, 1518px 1715px $star1, 658px 961px $star1,
            1139px 278px $star1, 221px 1839px $star1, 1009px 1508px $star1, 35px 1029px $star1, 891px 1715px $star1,
            863px 566px $star1, 796px 1461px $star1, 1021px 13px $star1, 446px 1617px $star1, 49px 1205px $star1,
            1947px 1413px $star1, 864px 1625px $star1, 257px 1686px $star1, 1293px 1185px $star1, 631px 224px $star1,
            1169px 887px $star1, 1906px 272px $star1, 1546px 558px $star1, 80px 518px $star1, 892px 1744px $star1,
            1986px 168px $star1, 1806px 1915px $star1, 1016px 1914px $star1, 501px 897px $star1, 294px 1579px $star1,
            1601px 12px $star1, 1126px 1344px $star1, 1902px 1208px $star1, 1505px 1780px $star1, 1681px 1186px $star1,
            74px 1573px $star1, 436px 1248px $star1, 1508px 723px $star1, 306px 1502px $star1, 833px 1141px $star1,
            92px 1511px $star1, 1331px 1572px $star1, 1092px 248px $star1, 651px 173px $star1, 1512px 572px $star1,
            1621px 175px $star1, 865px 1078px $star1, 511px 1112px $star1, 945px 1213px $star1, 44px 442px $star1,
            1586px 508px $star1, 1837px 1709px $star1, 1399px 1380px $star1, 555px 10px $star1, 1700px 1475px $star1,
            32px 1338px $star1, 94px 349px $star1, 1014px 1804px $star1, 597px 583px $star1, 429px 1581px $star1,
            647px 1881px $star1, 1286px 567px $star1, 1519px 495px $star1, 282px 834px $star1, 1219px 1160px $star1,
            431px 654px $star1, 321px 595px $star1, 1401px 1167px $star1, 1899px 581px $star1, 1101px 87px $star1,
            594px 1199px $star1, 1290px 915px $star1, 1169px 409px $star1, 152px 654px $star1, 1983px 360px $star1,
            1157px 1160px $star1, 1956px 93px $star1, 683px 968px $star1, 60px 21px $star1, 1848px 687px $star1,
            1653px 1774px $star1, 618px 695px $star1, 1581px 1030px $star1, 1332px 1122px $star1, 121px 537px $star1,
            265px 890px $star1, 1887px 786px $star1, 1014px 1487px $star1, 274px 453px $star1, 888px 1182px $star1,
            1221px 564px $star1, 1117px 1845px $star1, 881px 1244px $star1, 153px 72px $star1, 523px 661px $star1,
            282px 593px $star1, 890px 1321px $star1, 1669px 335px $star1, 190px 1840px $star1, 1774px 1763px $star1,
            1942px 217px $star1, 1007px 1804px $star1, 465px 628px $star1, 1689px 167px $star1, 202px 1347px $star1,
            942px 1602px $star1, 703px 1301px $star1, 1197px 910px $star1, 1016px 456px $star1, 1400px 1143px $star1,
            1855px 1248px $star1, 1936px 139px $star1, 947px 1px $star1, 991px 847px $star1, 1582px 595px $star1,
            156px 1833px $star1, 398px 1770px $star1, 1684px 361px $star1, 239px 769px $star1, 1358px 497px $star1,
            833px 1983px $star1, 831px 1593px $star1, 484px 1217px $star1, 686px 1105px $star1, 1577px 1322px $star1,
            1922px 1535px $star1, 409px 147px $star1, 156px 1274px $star1, 1226px 1849px $star1, 648px 487px $star1,
            546px 1623px $star1, 956px 46px $star1, 1961px 1145px $star1, 1338px 903px $star1, 582px 879px $star1,
            1643px 215px $star1, 1441px 1630px $star1, 763px 952px $star1, 1171px 696px $star1, 1187px 1667px $star1,
            1606px 1224px $star1, 1675px 1894px $star1, 1809px 220px $star1, 1910px 1516px $star1, 1426px 85px $star1,
            946px 715px $star1, 1731px 614px $star1, 999px 1288px $star1, 879px 644px $star1, 1080px 724px $star1,
            126px 1296px $star1, 1081px 376px $star1, 777px 319px $star1, 388px 5px $star1, 1264px 494px $star1,
            1555px 1506px $star1, 1389px 1486px $star1, 973px 1412px $star1, 1399px 847px $star1, 492px 255px $star1,
            1718px 1073px $star1, 821px 531px $star1, 274px 904px $star1, 1452px 17px $star1, 357px 1240px $star1,
            1189px 1228px $star1, 1312px 1515px $star1, 1357px 143px $star1, 657px 1338px $star1, 1380px 1844px $star1,
            1893px 1442px $star1, 1153px 1548px $star1, 1706px 115px $star1, 1369px 1195px $star1, 495px 847px $star1,
            267px 1824px $star1, 277px 671px $star1, 1141px 884px $star1, 744px 1057px $star1, 905px 1086px $star1,
            778px 190px $star1, 838px 323px $star1, 1908px 637px $star1, 333px 361px $star1, 351px 1187px $star1,
            613px 1389px $star1, 305px 707px $star1, 405px 868px $star1, 196px 878px $star1, 1946px 1614px $star1,
            328px 266px $star1, 306px 1716px $star1, 321px 41px $star1, 254px 771px $star1, 1900px 1335px $star1,
            1841px 1813px $star1, 1750px 1723px $star1, 1530px 1590px $star1, 1125px 1373px $star1, 1322px 1720px $star1,
            1127px 470px $star1, 702px 1727px $star1, 1060px 1840px $star1, 192px 1525px $star1, 1640px 839px $star1,
            387px 1652px $star1, 1776px 1855px $star1, 682px 737px $star1, 283px 6px $star1, 700px 707px $star1,
            295px 359px $star1, 804px 308px $star1, 1455px 1884px $star1, 1944px 285px $star1, 326px 4px $star1,
            719px 1565px $star1, 411px 1562px $star1, 171px 1204px $star1, 7px 715px $star1, 957px 693px $star1,
            1306px 496px $star1, 2000px 864px $star1, 934px 299px $star1, 1893px 1971px $star1, 771px 222px $star1,
            65px 63px $star1, 899px 1039px $star1, 328px 1541px $star1, 1488px 609px $star1, 1682px 111px $star1,
            313px 1733px $star1, 703px 609px $star1, 418px 802px $star1, 77px 829px $star1, 1097px 236px $star1,
            1757px 1922px $star1, 283px 782px $star1, 139px 1901px $star1, 82px 901px $star1, 1760px 514px $star1,
            1078px 848px $star1, 354px 1493px $star1, 760px 367px $star1, 1587px 1447px $star1, 1944px 618px $star1,
            1734px 1105px $star1, 603px 1417px $star1, 1438px 1593px $star1, 935px 1549px $star1, 1409px 1533px $star1,
            1479px 1105px $star1, 159px 1519px $star1, 1034px 782px $star1, 726px 1402px $star1, 597px 996px $star1,
            1229px 1787px $star1, 527px 1821px $star1, 1443px 1173px $star1, 1431px 1308px $star1, 1871px 1901px $star1,
            1713px 1913px $star1, 1134px 465px $star1, 1900px 1749px $star1, 1462px 1012px $star1, 485px 1367px $star1,
            345px 1244px $star1, 485px 1473px $star1, 897px 1111px $star1, 1066px 929px $star1, 1082px 961px $star1,
            421px 1875px $star1, 774px 850px $star1, 103px 969px $star1, 1606px 1378px $star1, 42px 254px $star1,
            1306px 1494px $star1, 1291px 1498px $star1, 939px 854px $star1, 1400px 1986px $star1, 1153px 542px $star1,
            105px 1018px $star1, 1913px 938px $star1, 1098px 371px $star1, 490px 260px $star1, 448px 947px $star1,
            100px 570px $star1, 1264px 1170px $star1, 740px 1202px $star1, 567px 454px $star1, 1779px 171px $star1,
            1872px 1954px $star1, 882px 1739px $star1, 1120px 1169px $star1, 216px 1999px $star1, 1741px 1091px $star1,
            1404px 905px $star1, 489px 1318px $star1, 933px 656px $star1, 78px 38px $star1, 278px 1268px $star1,
            247px 1510px $star1, 35px 35px $star1, 158px 1197px $star1, 576px 1362px $star1, 679px 893px $star1,
            1453px 408px $star1, 1249px 1545px $star1, 339px 1097px $star1, 129px 1718px $star1, 1170px 1541px $star1,
            633px 95px $star1, 808px 1387px $star1, 742px 1478px $star1, 460px 1960px $star1, 1402px 1674px $star1,
            530px 1440px $star1, 1993px 1935px $star1, 1353px 9px $star1, 1988px 951px $star1, 802px 407px $star1,
            1621px 395px $star1, 1629px 562px $star1, 1828px 949px $star1, 1404px 1620px $star1, 918px 1011px $star1,
            1281px 1306px $star1, 757px 205px $star1, 483px 1090px $star1, 823px 885px $star1, 59px 1305px $star1,
            1926px 155px $star1, 1474px 203px $star1, 793px 733px $star1, 1635px 1584px $star1, 717px 699px $star1,
            400px 1783px $star1, 202px 436px $star1, 1016px 1528px $star1, 1409px 731px $star1, 851px 1585px $star1,
            63px 134px $star1, 1884px 186px $star1, 1283px 198px $star1, 1313px 882px $star1, 544px 617px $star1,
            784px 1236px $star1, 1711px 808px $star1, 917px 1045px $star1, 1867px 842px $star1, 510px 815px $star1,
            1558px 1319px $star1, 81px 132px $star1, 37px 282px $star1, 1391px 772px $star1, 701px 1711px $star1,
            176px 249px $star1, 1430px 1031px $star1, 1948px 28px $star1, 702px 1739px $star1, 910px 558px $star1,
            390px 523px $star1, 1789px 1117px $star1, 151px 1492px $star1, 507px 1133px $star1, 1948px 541px $star1,
            1324px 1372px $star1, 1435px 1348px $star1, 1162px 1274px $star1, 929px 101px $star1, 644px 601px $star1,
            1337px 221px $star1, 366px 1139px $star1, 1851px 1011px $star1, 894px 1199px $star1, 1904px 882px $star1,
            1931px 843px $star1, 1441px 1996px $star1, 1735px 1991px $star1, 997px 346px $star1, 235px 558px $star1,
            1921px 1002px $star1, 193px 446px $star1, 1748px 626px $star1, 522px 635px $star1, 995px 1809px $star1,
            1356px 1969px $star1, 170px 236px $star1, 1060px 1319px $star1, 1435px 133px $star1, 602px 309px $star1,
            1415px 764px $star1, 937px 1863px $star1, 1353px 1579px $star1, 750px 253px $star1, 1423px 252px $star1,
            943px 1909px $star1, 716px 264px $star1, 71px 752px $star1, 42px 1213px $star1, 372px 75px $star1,
            1204px 1863px $star1, 1014px 609px $star1, 1822px 918px $star1, 1382px 313px $star1, 669px 884px $star1,
            148px 840px $star1, 48px 1656px $star1, 1265px 521px $star1, 1505px 1942px $star1, 644px 1366px $star1,
            1280px 88px $star1, 251px 1354px $star1, 1048px 213px $star1, 1487px 959px $star1, 1937px 859px $star1,
            802px 14px $star1, 1434px 592px $star1, 463px 1435px $star1, 975px 592px $star1, 1627px 1964px $star1,
            1459px 710px $star1, 673px 1176px $star1, 1753px 1146px $star1, 1873px 873px $star1, 1453px 1630px $star1,
            798px 773px $star1, 1158px 417px $star1, 926px 523px $star1, 160px 688px $star1, 1588px 1818px $star1,
            902px 1334px $star1, 1493px 1634px $star1, 668px 1541px $star1, 993px 1175px $star1, 1602px 798px $star1,
            1942px 1531px $star1, 112px 559px $star1, 37px 282px $star1, 1133px 1238px $star1, 1934px 1689px $star1,
            1227px 197px $star1, 126px 1846px $star1, 1177px 768px $star1, 1829px 816px $star1, 1287px 358px $star1,
            172px 1540px $star1, 1994px 664px $star1, 1226px 6px $star1, 1510px 1398px $star1, 1254px 883px $star1,
            1136px 823px $star1, 884px 933px $star1, 1349px 1321px $star1, 844px 1400px $star1, 908px 158px $star1,
            991px 1248px $star1, 98px 358px $star1, 1487px 77px $star1, 1777px 594px $star1, 515px 945px $star1,
            1944px 100px $star1, 524px 1154px $star1, 151px 1646px $star1, 1860px 1388px $star1, 1522px 1169px $star1,
            1790px 983px $star1, 1331px 577px $star1, 334px 1036px $star1, 1008px 1677px $star1, 761px 1474px $star1,
            681px 1525px $star1, 1140px 1720px $star1, 26px 1557px $star1, 1316px 1695px $star1, 1619px 1517px $star1,
            1389px 269px $star1, 1293px 258px $star1, 837px 1802px $star1, 1032px 941px $star1, 1626px 132px $star1,
            714px 628px $star1, 682px 774px $star1, 892px 1047px $star1, 1366px 1980px $star1, 467px 1506px $star1,
            1255px 180px $star1, 444px 246px $star1, 1056px 62px $star1, 773px 1726px $star1, 945px 1393px $star1,
            1691px 1436px $star1, 170px 175px $star1, 123px 633px $star1, 1032px 1470px $star1, 141px 882px $star1,
            1764px 1688px $star1, 1242px 261px $star1, 1149px 1694px $star1, 1160px 926px $star1, 238px 1827px $star1,
            777px 1936px $star1, 330px 1306px $star1, 265px 1504px $star1, 699px 787px $star1, 1166px 140px $star1,
            1444px 347px $star1, 1124px 1586px $star1, 1693px 1459px $star1, 1472px 1696px $star1, 1294px 139px $star1,
            807px 1772px $star1, 482px 489px $star1, 766px 463px $star1, 412px 744px $star1, 589px 98px $star1,
            1753px 1906px $star1, 49px 1248px $star1, 1943px 1042px $star1, 419px 176px $star1, 143px 1456px $star1,
            53px 1437px $star1, 1562px 303px $star1, 1110px 1021px $star1, 148px 591px $star1, 307px 1881px $star1,
            247px 433px $star1, 1127px 1084px $star1, 614px 313px $star1, 626px 1365px $star1, 13px 1315px $star1,
            278px 1708px $star1, 1545px 639px $star1, 831px 121px $star1, 417px 438px $star1, 1238px 1659px $star1,
            195px 998px $star1, 34px 1438px $star1, 506px 576px $star1, 958px 249px $star1, 1616px 178px $star1,
            1824px 826px $star1, 961px 1944px $star1, 472px 800px $star1, 1248px 1749px $star1, 871px 705px $star1,
            1443px 663px $star1, 1172px 476px $star1, 577px 1516px $star1, 257px 1516px $star1, 893px 892px $star1,
            680px 1004px $star1, 279px 1470px $star1, 1421px 1430px $star1, 316px 1224px $star1, 602px 1236px $star1,
            1392px 1324px $star1, 122px 978px $star1, 925px 89px $star1, 1785px 842px $star1, 159px 1543px $star1,
            670px 1608px $star1, 1148px 498px $star1, 338px 67px $star1, 1538px 460px $star1, 1197px 55px $star1,
            913px 672px $star1, 458px 1948px $star1, 1880px 339px $star1, 510px 1034px $star1, 1948px 1438px $star1,
            170px 972px $star1, 602px 1130px $star1, 1762px 348px $star1, 415px 1052px $star1, 1826px 214px $star1,
            1257px 554px $star1, 318px 814px $star1, 1345px 1841px $star1, 1310px 1642px $star1, 1580px 609px $star1,
            1797px 803px $star1, 1836px 1224px $star1, 1197px 575px $star1, 1164px 1468px $star1, 1990px 80px $star1,
            1509px 90px $star1, 80px 801px $star1, 691px 90px $star1, 1716px 849px $star1, 704px 672px $star1,
            256px 773px $star1, 1525px 507px $star1, 1593px 402px $star1, 1972px 34px $star1, 1247px 1716px $star1,
            103px 1323px $star1, 896px 176px $star1, 659px 738px $star1, 292px 865px $star1, 1206px 353px $star1,
            1584px 317px $star1, 1831px 414px $star1, 1262px 1115px $star1, 578px 1421px $star1, 1351px 1460px $star1,
            127px 1940px $star1, 945px 1453px $star1, 240px 1948px $star1, 938px 1033px $star1, 1905px 168px $star1,
            1782px 1869px $star1, 1352px 375px $star1, 566px 695px $star1, 363px 1401px $star1, 1053px 7px $star1,
            152px 806px $star1, 1761px 1429px $star1, 1701px 1826px $star1, 547px 1957px $star1, 1625px 709px $star1,
            837px 863px $star1, 2000px 1628px $star1, 971px 172px $star1, 1333px 432px $star1, 1637px 1886px $star1,
            34px 755px $star1, 1928px 255px $star1, 162px 1174px $star1, 875px 1178px $star1, 1013px 208px $star1,
            1543px 384px $star1, 1844px 680px $star1, 1862px 485px $star1, 975px 1134px $star1, 948px 608px $star1,
            71px 1260px $star1, 89px 755px $star1, 812px 550px $star1, 969px 46px $star1, 1568px 1274px $star1,
            375px 563px $star1, 1480px 1220px $star1, 725px 1170px $star1, 284px 1336px $star1, 1745px 1768px $star1,
            1159px 933px $star1;
        animation: animStar 50s linear infinite;
    }
    #stars:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1369px 1230px $star1, 710px 932px $star1, 178px 463px $star1, 1223px 1797px $star1,
            1912px 1494px $star1, 1277px 35px $star1, 536px 1508px $star1, 422px 974px $star1, 275px 1496px $star1,
            552px 1867px $star1, 253px 1632px $star1, 193px 1060px $star1, 1622px 751px $star1, 1121px 1953px $star1,
            259px 1379px $star1, 787px 810px $star1, 1605px 1245px $star1, 380px 1683px $star1, 1101px 1269px $star1,
            97px 1561px $star1, 587px 1143px $star1, 845px 1746px $star1, 1831px 1467px $star1, 1503px 1395px $star1,
            1584px 404px $star1, 226px 1234px $star1, 287px 879px $star1, 1139px 346px $star1, 1277px 1941px $star1,
            483px 453px $star1, 607px 150px $star1, 1204px 1345px $star1, 1008px 590px $star1, 681px 334px $star1,
            1672px 745px $star1, 1596px 449px $star1, 30px 1121px $star1, 1px 1054px $star1, 1238px 820px $star1,
            428px 1699px $star1, 1685px 1307px $star1, 1292px 157px $star1, 1625px 821px $star1, 545px 1113px $star1,
            37px 677px $star1, 814px 259px $star1, 51px 1113px $star1, 853px 891px $star1, 545px 468px $star1,
            390px 411px $star1, 107px 1642px $star1, 650px 408px $star1, 638px 647px $star1, 1701px 732px $star1,
            270px 1806px $star1, 27px 1913px $star1, 1594px 907px $star1, 1518px 1715px $star1, 658px 961px $star1,
            1139px 278px $star1, 221px 1839px $star1, 1009px 1508px $star1, 35px 1029px $star1, 891px 1715px $star1,
            863px 566px $star1, 796px 1461px $star1, 1021px 13px $star1, 446px 1617px $star1, 49px 1205px $star1,
            1947px 1413px $star1, 864px 1625px $star1, 257px 1686px $star1, 1293px 1185px $star1, 631px 224px $star1,
            1169px 887px $star1, 1906px 272px $star1, 1546px 558px $star1, 80px 518px $star1, 892px 1744px $star1,
            1986px 168px $star1, 1806px 1915px $star1, 1016px 1914px $star1, 501px 897px $star1, 294px 1579px $star1,
            1601px 12px $star1, 1126px 1344px $star1, 1902px 1208px $star1, 1505px 1780px $star1, 1681px 1186px $star1,
            74px 1573px $star1, 436px 1248px $star1, 1508px 723px $star1, 306px 1502px $star1, 833px 1141px $star1,
            92px 1511px $star1, 1331px 1572px $star1, 1092px 248px $star1, 651px 173px $star1, 1512px 572px $star1,
            1621px 175px $star1, 865px 1078px $star1, 511px 1112px $star1, 945px 1213px $star1, 44px 442px $star1,
            1586px 508px $star1, 1837px 1709px $star1, 1399px 1380px $star1, 555px 10px $star1, 1700px 1475px $star1,
            32px 1338px $star1, 94px 349px $star1, 1014px 1804px $star1, 597px 583px $star1, 429px 1581px $star1,
            647px 1881px $star1, 1286px 567px $star1, 1519px 495px $star1, 282px 834px $star1, 1219px 1160px $star1,
            431px 654px $star1, 321px 595px $star1, 1401px 1167px $star1, 1899px 581px $star1, 1101px 87px $star1,
            594px 1199px $star1, 1290px 915px $star1, 1169px 409px $star1, 152px 654px $star1, 1983px 360px $star1,
            1157px 1160px $star1, 1956px 93px $star1, 683px 968px $star1, 60px 21px $star1, 1848px 687px $star1,
            1653px 1774px $star1, 618px 695px $star1, 1581px 1030px $star1, 1332px 1122px $star1, 121px 537px $star1,
            265px 890px $star1, 1887px 786px $star1, 1014px 1487px $star1, 274px 453px $star1, 888px 1182px $star1,
            1221px 564px $star1, 1117px 1845px $star1, 881px 1244px $star1, 153px 72px $star1, 523px 661px $star1,
            282px 593px $star1, 890px 1321px $star1, 1669px 335px $star1, 190px 1840px $star1, 1774px 1763px $star1,
            1942px 217px $star1, 1007px 1804px $star1, 465px 628px $star1, 1689px 167px $star1, 202px 1347px $star1,
            942px 1602px $star1, 703px 1301px $star1, 1197px 910px $star1, 1016px 456px $star1, 1400px 1143px $star1,
            1855px 1248px $star1, 1936px 139px $star1, 947px 1px $star1, 991px 847px $star1, 1582px 595px $star1,
            156px 1833px $star1, 398px 1770px $star1, 1684px 361px $star1, 239px 769px $star1, 1358px 497px $star1,
            833px 1983px $star1, 831px 1593px $star1, 484px 1217px $star1, 686px 1105px $star1, 1577px 1322px $star1,
            1922px 1535px $star1, 409px 147px $star1, 156px 1274px $star1, 1226px 1849px $star1, 648px 487px $star1,
            546px 1623px $star1, 956px 46px $star1, 1961px 1145px $star1, 1338px 903px $star1, 582px 879px $star1,
            1643px 215px $star1, 1441px 1630px $star1, 763px 952px $star1, 1171px 696px $star1, 1187px 1667px $star1,
            1606px 1224px $star1, 1675px 1894px $star1, 1809px 220px $star1, 1910px 1516px $star1, 1426px 85px $star1,
            946px 715px $star1, 1731px 614px $star1, 999px 1288px $star1, 879px 644px $star1, 1080px 724px $star1,
            126px 1296px $star1, 1081px 376px $star1, 777px 319px $star1, 388px 5px $star1, 1264px 494px $star1,
            1555px 1506px $star1, 1389px 1486px $star1, 973px 1412px $star1, 1399px 847px $star1, 492px 255px $star1,
            1718px 1073px $star1, 821px 531px $star1, 274px 904px $star1, 1452px 17px $star1, 357px 1240px $star1,
            1189px 1228px $star1, 1312px 1515px $star1, 1357px 143px $star1, 657px 1338px $star1, 1380px 1844px $star1,
            1893px 1442px $star1, 1153px 1548px $star1, 1706px 115px $star1, 1369px 1195px $star1, 495px 847px $star1,
            267px 1824px $star1, 277px 671px $star1, 1141px 884px $star1, 744px 1057px $star1, 905px 1086px $star1,
            778px 190px $star1, 838px 323px $star1, 1908px 637px $star1, 333px 361px $star1, 351px 1187px $star1,
            613px 1389px $star1, 305px 707px $star1, 405px 868px $star1, 196px 878px $star1, 1946px 1614px $star1,
            328px 266px $star1, 306px 1716px $star1, 321px 41px $star1, 254px 771px $star1, 1900px 1335px $star1,
            1841px 1813px $star1, 1750px 1723px $star1, 1530px 1590px $star1, 1125px 1373px $star1, 1322px 1720px $star1,
            1127px 470px $star1, 702px 1727px $star1, 1060px 1840px $star1, 192px 1525px $star1, 1640px 839px $star1,
            387px 1652px $star1, 1776px 1855px $star1, 682px 737px $star1, 283px 6px $star1, 700px 707px $star1,
            295px 359px $star1, 804px 308px $star1, 1455px 1884px $star1, 1944px 285px $star1, 326px 4px $star1,
            719px 1565px $star1, 411px 1562px $star1, 171px 1204px $star1, 7px 715px $star1, 957px 693px $star1,
            1306px 496px $star1, 2000px 864px $star1, 934px 299px $star1, 1893px 1971px $star1, 771px 222px $star1,
            65px 63px $star1, 899px 1039px $star1, 328px 1541px $star1, 1488px 609px $star1, 1682px 111px $star1,
            313px 1733px $star1, 703px 609px $star1, 418px 802px $star1, 77px 829px $star1, 1097px 236px $star1,
            1757px 1922px $star1, 283px 782px $star1, 139px 1901px $star1, 82px 901px $star1, 1760px 514px $star1,
            1078px 848px $star1, 354px 1493px $star1, 760px 367px $star1, 1587px 1447px $star1, 1944px 618px $star1,
            1734px 1105px $star1, 603px 1417px $star1, 1438px 1593px $star1, 935px 1549px $star1, 1409px 1533px $star1,
            1479px 1105px $star1, 159px 1519px $star1, 1034px 782px $star1, 726px 1402px $star1, 597px 996px $star1,
            1229px 1787px $star1, 527px 1821px $star1, 1443px 1173px $star1, 1431px 1308px $star1, 1871px 1901px $star1,
            1713px 1913px $star1, 1134px 465px $star1, 1900px 1749px $star1, 1462px 1012px $star1, 485px 1367px $star1,
            345px 1244px $star1, 485px 1473px $star1, 897px 1111px $star1, 1066px 929px $star1, 1082px 961px $star1,
            421px 1875px $star1, 774px 850px $star1, 103px 969px $star1, 1606px 1378px $star1, 42px 254px $star1,
            1306px 1494px $star1, 1291px 1498px $star1, 939px 854px $star1, 1400px 1986px $star1, 1153px 542px $star1,
            105px 1018px $star1, 1913px 938px $star1, 1098px 371px $star1, 490px 260px $star1, 448px 947px $star1,
            100px 570px $star1, 1264px 1170px $star1, 740px 1202px $star1, 567px 454px $star1, 1779px 171px $star1,
            1872px 1954px $star1, 882px 1739px $star1, 1120px 1169px $star1, 216px 1999px $star1, 1741px 1091px $star1,
            1404px 905px $star1, 489px 1318px $star1, 933px 656px $star1, 78px 38px $star1, 278px 1268px $star1,
            247px 1510px $star1, 35px 35px $star1, 158px 1197px $star1, 576px 1362px $star1, 679px 893px $star1,
            1453px 408px $star1, 1249px 1545px $star1, 339px 1097px $star1, 129px 1718px $star1, 1170px 1541px $star1,
            633px 95px $star1, 808px 1387px $star1, 742px 1478px $star1, 460px 1960px $star1, 1402px 1674px $star1,
            530px 1440px $star1, 1993px 1935px $star1, 1353px 9px $star1, 1988px 951px $star1, 802px 407px $star1,
            1621px 395px $star1, 1629px 562px $star1, 1828px 949px $star1, 1404px 1620px $star1, 918px 1011px $star1,
            1281px 1306px $star1, 757px 205px $star1, 483px 1090px $star1, 823px 885px $star1, 59px 1305px $star1,
            1926px 155px $star1, 1474px 203px $star1, 793px 733px $star1, 1635px 1584px $star1, 717px 699px $star1,
            400px 1783px $star1, 202px 436px $star1, 1016px 1528px $star1, 1409px 731px $star1, 851px 1585px $star1,
            63px 134px $star1, 1884px 186px $star1, 1283px 198px $star1, 1313px 882px $star1, 544px 617px $star1,
            784px 1236px $star1, 1711px 808px $star1, 917px 1045px $star1, 1867px 842px $star1, 510px 815px $star1,
            1558px 1319px $star1, 81px 132px $star1, 37px 282px $star1, 1391px 772px $star1, 701px 1711px $star1,
            176px 249px $star1, 1430px 1031px $star1, 1948px 28px $star1, 702px 1739px $star1, 910px 558px $star1,
            390px 523px $star1, 1789px 1117px $star1, 151px 1492px $star1, 507px 1133px $star1, 1948px 541px $star1,
            1324px 1372px $star1, 1435px 1348px $star1, 1162px 1274px $star1, 929px 101px $star1, 644px 601px $star1,
            1337px 221px $star1, 366px 1139px $star1, 1851px 1011px $star1, 894px 1199px $star1, 1904px 882px $star1,
            1931px 843px $star1, 1441px 1996px $star1, 1735px 1991px $star1, 997px 346px $star1, 235px 558px $star1,
            1921px 1002px $star1, 193px 446px $star1, 1748px 626px $star1, 522px 635px $star1, 995px 1809px $star1,
            1356px 1969px $star1, 170px 236px $star1, 1060px 1319px $star1, 1435px 133px $star1, 602px 309px $star1,
            1415px 764px $star1, 937px 1863px $star1, 1353px 1579px $star1, 750px 253px $star1, 1423px 252px $star1,
            943px 1909px $star1, 716px 264px $star1, 71px 752px $star1, 42px 1213px $star1, 372px 75px $star1,
            1204px 1863px $star1, 1014px 609px $star1, 1822px 918px $star1, 1382px 313px $star1, 669px 884px $star1,
            148px 840px $star1, 48px 1656px $star1, 1265px 521px $star1, 1505px 1942px $star1, 644px 1366px $star1,
            1280px 88px $star1, 251px 1354px $star1, 1048px 213px $star1, 1487px 959px $star1, 1937px 859px $star1,
            802px 14px $star1, 1434px 592px $star1, 463px 1435px $star1, 975px 592px $star1, 1627px 1964px $star1,
            1459px 710px $star1, 673px 1176px $star1, 1753px 1146px $star1, 1873px 873px $star1, 1453px 1630px $star1,
            798px 773px $star1, 1158px 417px $star1, 926px 523px $star1, 160px 688px $star1, 1588px 1818px $star1,
            902px 1334px $star1, 1493px 1634px $star1, 668px 1541px $star1, 993px 1175px $star1, 1602px 798px $star1,
            1942px 1531px $star1, 112px 559px $star1, 37px 282px $star1, 1133px 1238px $star1, 1934px 1689px $star1,
            1227px 197px $star1, 126px 1846px $star1, 1177px 768px $star1, 1829px 816px $star1, 1287px 358px $star1,
            172px 1540px $star1, 1994px 664px $star1, 1226px 6px $star1, 1510px 1398px $star1, 1254px 883px $star1,
            1136px 823px $star1, 884px 933px $star1, 1349px 1321px $star1, 844px 1400px $star1, 908px 158px $star1,
            991px 1248px $star1, 98px 358px $star1, 1487px 77px $star1, 1777px 594px $star1, 515px 945px $star1,
            1944px 100px $star1, 524px 1154px $star1, 151px 1646px $star1, 1860px 1388px $star1, 1522px 1169px $star1,
            1790px 983px $star1, 1331px 577px $star1, 334px 1036px $star1, 1008px 1677px $star1, 761px 1474px $star1,
            681px 1525px $star1, 1140px 1720px $star1, 26px 1557px $star1, 1316px 1695px $star1, 1619px 1517px $star1,
            1389px 269px $star1, 1293px 258px $star1, 837px 1802px $star1, 1032px 941px $star1, 1626px 132px $star1,
            714px 628px $star1, 682px 774px $star1, 892px 1047px $star1, 1366px 1980px $star1, 467px 1506px $star1,
            1255px 180px $star1, 444px 246px $star1, 1056px 62px $star1, 773px 1726px $star1, 945px 1393px $star1,
            1691px 1436px $star1, 170px 175px $star1, 123px 633px $star1, 1032px 1470px $star1, 141px 882px $star1,
            1764px 1688px $star1, 1242px 261px $star1, 1149px 1694px $star1, 1160px 926px $star1, 238px 1827px $star1,
            777px 1936px $star1, 330px 1306px $star1, 265px 1504px $star1, 699px 787px $star1, 1166px 140px $star1,
            1444px 347px $star1, 1124px 1586px $star1, 1693px 1459px $star1, 1472px 1696px $star1, 1294px 139px $star1,
            807px 1772px $star1, 482px 489px $star1, 766px 463px $star1, 412px 744px $star1, 589px 98px $star1,
            1753px 1906px $star1, 49px 1248px $star1, 1943px 1042px $star1, 419px 176px $star1, 143px 1456px $star1,
            53px 1437px $star1, 1562px 303px $star1, 1110px 1021px $star1, 148px 591px $star1, 307px 1881px $star1,
            247px 433px $star1, 1127px 1084px $star1, 614px 313px $star1, 626px 1365px $star1, 13px 1315px $star1,
            278px 1708px $star1, 1545px 639px $star1, 831px 121px $star1, 417px 438px $star1, 1238px 1659px $star1,
            195px 998px $star1, 34px 1438px $star1, 506px 576px $star1, 958px 249px $star1, 1616px 178px $star1,
            1824px 826px $star1, 961px 1944px $star1, 472px 800px $star1, 1248px 1749px $star1, 871px 705px $star1,
            1443px 663px $star1, 1172px 476px $star1, 577px 1516px $star1, 257px 1516px $star1, 893px 892px $star1,
            680px 1004px $star1, 279px 1470px $star1, 1421px 1430px $star1, 316px 1224px $star1, 602px 1236px $star1,
            1392px 1324px $star1, 122px 978px $star1, 925px 89px $star1, 1785px 842px $star1, 159px 1543px $star1,
            670px 1608px $star1, 1148px 498px $star1, 338px 67px $star1, 1538px 460px $star1, 1197px 55px $star1,
            913px 672px $star1, 458px 1948px $star1, 1880px 339px $star1, 510px 1034px $star1, 1948px 1438px $star1,
            170px 972px $star1, 602px 1130px $star1, 1762px 348px $star1, 415px 1052px $star1, 1826px 214px $star1,
            1257px 554px $star1, 318px 814px $star1, 1345px 1841px $star1, 1310px 1642px $star1, 1580px 609px $star1,
            1797px 803px $star1, 1836px 1224px $star1, 1197px 575px $star1, 1164px 1468px $star1, 1990px 80px $star1,
            1509px 90px $star1, 80px 801px $star1, 691px 90px $star1, 1716px 849px $star1, 704px 672px $star1,
            256px 773px $star1, 1525px 507px $star1, 1593px 402px $star1, 1972px 34px $star1, 1247px 1716px $star1,
            103px 1323px $star1, 896px 176px $star1, 659px 738px $star1, 292px 865px $star1, 1206px 353px $star1,
            1584px 317px $star1, 1831px 414px $star1, 1262px 1115px $star1, 578px 1421px $star1, 1351px 1460px $star1,
            127px 1940px $star1, 945px 1453px $star1, 240px 1948px $star1, 938px 1033px $star1, 1905px 168px $star1,
            1782px 1869px $star1, 1352px 375px $star1, 566px 695px $star1, 363px 1401px $star1, 1053px 7px $star1,
            152px 806px $star1, 1761px 1429px $star1, 1701px 1826px $star1, 547px 1957px $star1, 1625px 709px $star1,
            837px 863px $star1, 2000px 1628px $star1, 971px 172px $star1, 1333px 432px $star1, 1637px 1886px $star1,
            34px 755px $star1, 1928px 255px $star1, 162px 1174px $star1, 875px 1178px $star1, 1013px 208px $star1,
            1543px 384px $star1, 1844px 680px $star1, 1862px 485px $star1, 975px 1134px $star1, 948px 608px $star1,
            71px 1260px $star1, 89px 755px $star1, 812px 550px $star1, 969px 46px $star1, 1568px 1274px $star1,
            375px 563px $star1, 1480px 1220px $star1, 725px 1170px $star1, 284px 1336px $star1, 1745px 1768px $star1,
            1159px 933px $star1;
    }

    #stars2 {
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 938px 130px $star2, 938px 277px $star2, 17px 274px $star2, 1806px 738px $star2, 1110px 760px $star2,
            683px 516px $star2, 1584px 412px $star2, 266px 437px $star2, 1738px 1612px $star2, 573px 1352px $star2,
            1886px 1749px $star2, 268px 1599px $star2, 1709px 1336px $star2, 1589px 461px $star2, 1114px 1557px $star2,
            1184px 1817px $star2, 797px 476px $star2, 976px 645px $star2, 88px 1434px $star2, 487px 1160px $star2,
            537px 556px $star2, 184px 1106px $star2, 1879px 438px $star2, 1672px 1453px $star2, 779px 1335px $star2,
            1674px 1128px $star2, 1243px 155px $star2, 1354px 401px $star2, 1524px 1816px $star2, 1199px 1684px $star2,
            1701px 464px $star2, 344px 369px $star2, 718px 1004px $star2, 263px 1386px $star2, 133px 1398px $star2,
            11px 1001px $star2, 263px 96px $star2, 1009px 1384px $star2, 1558px 814px $star2, 183px 927px $star2,
            1163px 1597px $star2, 107px 51px $star2, 673px 1055px $star2, 1552px 1864px $star2, 1908px 165px $star2,
            267px 1118px $star2, 1160px 85px $star2, 523px 406px $star2, 415px 926px $star2, 1781px 1688px $star2,
            203px 148px $star2, 1384px 1697px $star2, 998px 1661px $star2, 249px 1437px $star2, 1947px 1274px $star2,
            1628px 1529px $star2, 1039px 1462px $star2, 586px 155px $star2, 409px 1738px $star2, 1263px 1979px $star2,
            1702px 803px $star2, 1719px 1176px $star2, 1333px 637px $star2, 1013px 1356px $star2, 1972px 523px $star2,
            548px 1218px $star2, 347px 1086px $star2, 321px 219px $star2, 1382px 544px $star2, 1468px 1808px $star2,
            1965px 598px $star2, 633px 716px $star2, 266px 438px $star2, 894px 165px $star2, 1045px 1956px $star2,
            1668px 1762px $star2, 904px 1004px $star2, 973px 1713px $star2, 160px 1700px $star2, 100px 1534px $star2,
            1157px 412px $star2, 893px 934px $star2, 1835px 368px $star2, 1810px 281px $star2, 290px 714px $star2,
            1282px 457px $star2, 1467px 672px $star2, 1682px 1219px $star2, 1739px 1174px $star2, 1400px 977px $star2,
            1383px 904px $star2, 326px 1284px $star2, 251px 710px $star2, 1263px 742px $star2, 1020px 1086px $star2,
            1078px 1625px $star2, 1576px 551px $star2, 90px 1965px $star2, 803px 1748px $star2, 1903px 56px $star2,
            953px 416px $star2, 1916px 197px $star2, 265px 1259px $star2, 1063px 1130px $star2, 400px 1629px $star2,
            1044px 953px $star2, 1301px 812px $star2, 1602px 1405px $star2, 1170px 55px $star2, 1681px 278px $star2,
            1388px 1931px $star2, 659px 1554px $star2, 1064px 686px $star2, 1189px 491px $star2, 885px 1331px $star2,
            1998px 1430px $star2, 702px 1367px $star2, 727px 1926px $star2, 239px 918px $star2, 77px 1340px $star2,
            1702px 1477px $star2, 759px 764px $star2, 453px 1598px $star2, 1550px 982px $star2, 1586px 1578px $star2,
            438px 1337px $star2, 1778px 1322px $star2, 361px 1493px $star2, 1663px 1711px $star2, 1594px 284px $star2,
            656px 363px $star2, 1810px 715px $star2, 517px 1697px $star2, 1438px 819px $star2, 1387px 1574px $star2,
            1250px 426px $star2, 1859px 508px $star2, 1157px 1219px $star2, 1058px 14px $star2, 133px 837px $star2,
            1141px 1797px $star2, 837px 1274px $star2, 63px 331px $star2, 1018px 1887px $star2, 642px 882px $star2,
            574px 1453px $star2, 1158px 1777px $star2, 338px 1149px $star2, 327px 1462px $star2, 291px 1398px $star2,
            998px 1288px $star2, 652px 478px $star2, 134px 218px $star2, 8px 1850px $star2, 1985px 1521px $star2,
            960px 727px $star2, 1087px 1175px $star2, 494px 1254px $star2, 1858px 999px $star2, 1884px 400px $star2,
            1612px 216px $star2, 1563px 1007px $star2, 1272px 44px $star2, 195px 1322px $star2, 1698px 1382px $star2,
            29px 1289px $star2, 1364px 109px $star2, 1036px 1895px $star2, 1914px 1447px $star2, 1380px 333px $star2,
            1341px 1498px $star2, 1430px 1790px $star2, 1550px 643px $star2, 623px 1437px $star2, 356px 781px $star2,
            416px 228px $star2, 584px 1055px $star2, 848px 1436px $star2, 1649px 143px $star2, 1549px 517px $star2,
            40px 1824px $star2, 927px 847px $star2, 1672px 1863px $star2, 1397px 1960px $star2, 836px 1316px $star2,
            1076px 473px $star2, 673px 24px $star2, 448px 637px $star2, 118px 1181px $star2, 338px 1091px $star2,
            1950px 684px $star2, 859px 803px $star2, 1657px 1191px $star2, 326px 929px $star2, 1251px 142px $star2,
            892px 884px $star2, 65px 505px $star2, 1487px 518px $star2, 1887px 481px $star2, 84px 1197px $star2;
        animation: animStar 100s linear infinite;
    }
    #stars2:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 938px 130px $star2, 938px 277px $star2, 17px 274px $star2, 1806px 738px $star2, 1110px 760px $star2,
            683px 516px $star2, 1584px 412px $star2, 266px 437px $star2, 1738px 1612px $star2, 573px 1352px $star2,
            1886px 1749px $star2, 268px 1599px $star2, 1709px 1336px $star2, 1589px 461px $star2, 1114px 1557px $star2,
            1184px 1817px $star2, 797px 476px $star2, 976px 645px $star2, 88px 1434px $star2, 487px 1160px $star2,
            537px 556px $star2, 184px 1106px $star2, 1879px 438px $star2, 1672px 1453px $star2, 779px 1335px $star2,
            1674px 1128px $star2, 1243px 155px $star2, 1354px 401px $star2, 1524px 1816px $star2, 1199px 1684px $star2,
            1701px 464px $star2, 344px 369px $star2, 718px 1004px $star2, 263px 1386px $star2, 133px 1398px $star2,
            11px 1001px $star2, 263px 96px $star2, 1009px 1384px $star2, 1558px 814px $star2, 183px 927px $star2,
            1163px 1597px $star2, 107px 51px $star2, 673px 1055px $star2, 1552px 1864px $star2, 1908px 165px $star2,
            267px 1118px $star2, 1160px 85px $star2, 523px 406px $star2, 415px 926px $star2, 1781px 1688px $star2,
            203px 148px $star2, 1384px 1697px $star2, 998px 1661px $star2, 249px 1437px $star2, 1947px 1274px $star2,
            1628px 1529px $star2, 1039px 1462px $star2, 586px 155px $star2, 409px 1738px $star2, 1263px 1979px $star2,
            1702px 803px $star2, 1719px 1176px $star2, 1333px 637px $star2, 1013px 1356px $star2, 1972px 523px $star2,
            548px 1218px $star2, 347px 1086px $star2, 321px 219px $star2, 1382px 544px $star2, 1468px 1808px $star2,
            1965px 598px $star2, 633px 716px $star2, 266px 438px $star2, 894px 165px $star2, 1045px 1956px $star2,
            1668px 1762px $star2, 904px 1004px $star2, 973px 1713px $star2, 160px 1700px $star2, 100px 1534px $star2,
            1157px 412px $star2, 893px 934px $star2, 1835px 368px $star2, 1810px 281px $star2, 290px 714px $star2,
            1282px 457px $star2, 1467px 672px $star2, 1682px 1219px $star2, 1739px 1174px $star2, 1400px 977px $star2,
            1383px 904px $star2, 326px 1284px $star2, 251px 710px $star2, 1263px 742px $star2, 1020px 1086px $star2,
            1078px 1625px $star2, 1576px 551px $star2, 90px 1965px $star2, 803px 1748px $star2, 1903px 56px $star2,
            953px 416px $star2, 1916px 197px $star2, 265px 1259px $star2, 1063px 1130px $star2, 400px 1629px $star2,
            1044px 953px $star2, 1301px 812px $star2, 1602px 1405px $star2, 1170px 55px $star2, 1681px 278px $star2,
            1388px 1931px $star2, 659px 1554px $star2, 1064px 686px $star2, 1189px 491px $star2, 885px 1331px $star2,
            1998px 1430px $star2, 702px 1367px $star2, 727px 1926px $star2, 239px 918px $star2, 77px 1340px $star2,
            1702px 1477px $star2, 759px 764px $star2, 453px 1598px $star2, 1550px 982px $star2, 1586px 1578px $star2,
            438px 1337px $star2, 1778px 1322px $star2, 361px 1493px $star2, 1663px 1711px $star2, 1594px 284px $star2,
            656px 363px $star2, 1810px 715px $star2, 517px 1697px $star2, 1438px 819px $star2, 1387px 1574px $star2,
            1250px 426px $star2, 1859px 508px $star2, 1157px 1219px $star2, 1058px 14px $star2, 133px 837px $star2,
            1141px 1797px $star2, 837px 1274px $star2, 63px 331px $star2, 1018px 1887px $star2, 642px 882px $star2,
            574px 1453px $star2, 1158px 1777px $star2, 338px 1149px $star2, 327px 1462px $star2, 291px 1398px $star2,
            998px 1288px $star2, 652px 478px $star2, 134px 218px $star2, 8px 1850px $star2, 1985px 1521px $star2,
            960px 727px $star2, 1087px 1175px $star2, 494px 1254px $star2, 1858px 999px $star2, 1884px 400px $star2,
            1612px 216px $star2, 1563px 1007px $star2, 1272px 44px $star2, 195px 1322px $star2, 1698px 1382px $star2,
            29px 1289px $star2, 1364px 109px $star2, 1036px 1895px $star2, 1914px 1447px $star2, 1380px 333px $star2,
            1341px 1498px $star2, 1430px 1790px $star2, 1550px 643px $star2, 623px 1437px $star2, 356px 781px $star2,
            416px 228px $star2, 584px 1055px $star2, 848px 1436px $star2, 1649px 143px $star2, 1549px 517px $star2,
            40px 1824px $star2, 927px 847px $star2, 1672px 1863px $star2, 1397px 1960px $star2, 836px 1316px $star2,
            1076px 473px $star2, 673px 24px $star2, 448px 637px $star2, 118px 1181px $star2, 338px 1091px $star2,
            1950px 684px $star2, 859px 803px $star2, 1657px 1191px $star2, 326px 929px $star2, 1251px 142px $star2,
            892px 884px $star2, 65px 505px $star2, 1487px 518px $star2, 1887px 481px $star2, 84px 1197px $star2;
    }

    #stars3 {
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 116px 237px $star3, 1995px 128px $star3, 1387px 1883px $star3, 1539px 294px $star3,
            1361px 570px $star3, 887px 1254px $star3, 1917px 1336px $star3, 585px 1065px $star3, 1448px 719px $star3,
            1158px 559px $star3, 653px 1463px $star3, 288px 1617px $star3, 1541px 509px $star3, 1372px 496px $star3,
            207px 676px $star3, 1534px 1872px $star3, 1869px 1593px $star3, 193px 1668px $star3, 1770px 1474px $star3,
            1575px 1646px $star3, 808px 1808px $star3, 412px 1222px $star3, 1063px 502px $star3, 937px 355px $star3,
            1253px 1781px $star3, 66px 727px $star3, 1524px 622px $star3, 29px 1392px $star3, 765px 824px $star3,
            436px 422px $star3, 578px 600px $star3, 893px 1641px $star3, 1603px 1304px $star3, 1643px 1681px $star3,
            1659px 917px $star3, 1070px 912px $star3, 1341px 96px $star3, 900px 1285px $star3, 1454px 1702px $star3,
            1519px 1899px $star3, 1688px 1483px $star3, 807px 838px $star3, 1296px 525px $star3, 36px 922px $star3,
            367px 1982px $star3, 264px 1595px $star3, 957px 570px $star3, 1657px 1079px $star3, 1493px 613px $star3,
            1230px 1288px $star3, 1716px 1925px $star3, 1618px 1191px $star3, 1717px 352px $star3, 1140px 200px $star3,
            182px 1648px $star3, 455px 197px $star3, 1349px 1068px $star3, 160px 1028px $star3, 739px 146px $star3,
            576px 1067px $star3, 812px 595px $star3, 1049px 212px $star3, 1285px 1064px $star3, 232px 1134px $star3,
            1314px 628px $star3, 1916px 1643px $star3, 1675px 1353px $star3, 241px 92px $star3, 311px 754px $star3,
            1373px 1077px $star3, 110px 1722px $star3, 83px 1640px $star3, 1684px 76px $star3, 459px 1294px $star3,
            681px 740px $star3, 1777px 1816px $star3, 1536px 1270px $star3, 1973px 143px $star3, 1827px 1541px $star3,
            1441px 288px $star3, 1424px 1918px $star3, 1918px 1835px $star3, 1049px 1724px $star3, 1825px 1196px $star3,
            103px 1961px $star3, 1589px 1166px $star3, 767px 337px $star3, 1749px 1750px $star3, 687px 1090px $star3,
            1214px 933px $star3, 1329px 1735px $star3, 1164px 791px $star3, 189px 21px $star3, 1568px 259px $star3,
            645px 1170px $star3, 1632px 158px $star3, 989px 317px $star3, 577px 1665px $star3, 488px 890px $star3,
            62px 44px $star3;
        animation: animStar 150s linear infinite;
    }
    #stars3:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 116px 237px $star3, 1995px 128px $star3, 1387px 1883px $star3, 1539px 294px $star3,
            1361px 570px $star3, 887px 1254px $star3, 1917px 1336px $star3, 585px 1065px $star3, 1448px 719px $star3,
            1158px 559px $star3, 653px 1463px $star3, 288px 1617px $star3, 1541px 509px $star3, 1372px 496px $star3,
            207px 676px $star3, 1534px 1872px $star3, 1869px 1593px $star3, 193px 1668px $star3, 1770px 1474px $star3,
            1575px 1646px $star3, 808px 1808px $star3, 412px 1222px $star3, 1063px 502px $star3, 937px 355px $star3,
            1253px 1781px $star3, 66px 727px $star3, 1524px 622px $star3, 29px 1392px $star3, 765px 824px $star3,
            436px 422px $star3, 578px 600px $star3, 893px 1641px $star3, 1603px 1304px $star3, 1643px 1681px $star3,
            1659px 917px $star3, 1070px 912px $star3, 1341px 96px $star3, 900px 1285px $star3, 1454px 1702px $star3,
            1519px 1899px $star3, 1688px 1483px $star3, 807px 838px $star3, 1296px 525px $star3, 36px 922px $star3,
            367px 1982px $star3, 264px 1595px $star3, 957px 570px $star3, 1657px 1079px $star3, 1493px 613px $star3,
            1230px 1288px $star3, 1716px 1925px $star3, 1618px 1191px $star3, 1717px 352px $star3, 1140px 200px $star3,
            182px 1648px $star3, 455px 197px $star3, 1349px 1068px $star3, 160px 1028px $star3, 739px 146px $star3,
            576px 1067px $star3, 812px 595px $star3, 1049px 212px $star3, 1285px 1064px $star3, 232px 1134px $star3,
            1314px 628px $star3, 1916px 1643px $star3, 1675px 1353px $star3, 241px 92px $star3, 311px 754px $star3,
            1373px 1077px $star3, 110px 1722px $star3, 83px 1640px $star3, 1684px 76px $star3, 459px 1294px $star3,
            681px 740px $star3, 1777px 1816px $star3, 1536px 1270px $star3, 1973px 143px $star3, 1827px 1541px $star3,
            1441px 288px $star3, 1424px 1918px $star3, 1918px 1835px $star3, 1049px 1724px $star3, 1825px 1196px $star3,
            103px 1961px $star3, 1589px 1166px $star3, 767px 337px $star3, 1749px 1750px $star3, 687px 1090px $star3,
            1214px 933px $star3, 1329px 1735px $star3, 1164px 791px $star3, 189px 21px $star3, 1568px 259px $star3,
            645px 1170px $star3, 1632px 158px $star3, 989px 317px $star3, 577px 1665px $star3, 488px 890px $star3,
            62px 44px $star3;
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-2000px);
        }
    }
}